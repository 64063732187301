input[type='file'] {
  display: none;
}

.custom-file-upload {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  cursor: pointer;

  width: 100%;
  height: 45px;

  padding: 5px;
  margin: 10px;

  border-radius: 8px;
}
